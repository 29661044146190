/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import './headerStyle.scss';
import CtaButton from '../ctaButton/cta';
import { LanguageDropdown } from '../dropDown/languageDropDown';
import { ReactComponent as ArrowSvg } from '../../../assets/images/arrow/icon_arrow_down.svg';
import CustomizedAccordions from '../../../shared/components/accordian/accordian';
import { Context as HeaderContext } from '../../context/Header-Context';
import { Context as HeaderFooterAssetContext } from '../../context/Assets-Context';
import { NavLink } from 'react-router-dom';

const Header = (props) => {
	const [menu, setMenu] = useState(props.menu);
	const [menuHeight, setMenuHeight] = useState(0);
	const { state: headerContext } = useContext(HeaderContext);
	const { state: headerFooterContextState } = useContext(HeaderFooterAssetContext);
	const headerFooterContent = headerFooterContextState.headerFooterContent;

	useEffect(async () => {
		getHeaderHeight();
	}, []);

	/**
	 * handleMenuClick method is used to open sub menu
	 * @param {*} tag is name of clicked menu
	 */
	const handleMenuClick = (tag) => {
		setMenu(tag);
		getElementHeight(tag, true);

	};


	/**
	 * handleMenuOut method is used to close sub menu
	 * @param {*} tag is name of clicked menu
	 */
	const handleMenuOut = (tag) => {
		console.log('ayush');
		setMenu("");
		getElementHeight(tag, false);
	};

	/**
	 * getElementHeight method is used to calculate height of submenu
	 * @param {*} tag 
	 * @param {*} open 
	 */
	const getElementHeight = (tag, open) => {
		const element = document.getElementById(tag);
		let height = 0;
		if (element && open) {
			height = element.clientHeight;
		} else {
			height = 0;
		}
		setMenuHeight(height);
	};

	/**
	 * getHeaderHeight is used to get header height and set that height to top of its clone div
	 * that is being used in mobile view also
	 */
	const getHeaderHeight = () => {
		const siteHeader = document.getElementById('siteHeader');
		const headerClone = document.getElementById('headerClone');
		const menuOpen = document.getElementById('menu-open');

		if (siteHeader) {
			headerClone.style.height = `${siteHeader.clientHeight}px`;
			menuOpen.style.top = `${siteHeader.clientHeight}px`;
		}
	};

	return (
		<header>
			{/* header start */ }
			<div className='siteHeader' id="siteHeader">
				<div className="container">
					<div className="row-wrap">
						<div className="logo-box">
							<div className="for-desktop">
								<NavLink to="/" className='logo'>
									<img src={ headerFooterContent && headerFooterContent.website_logo ? headerFooterContent.website_logo.url : `${process.env.PUBLIC_URL}/images/demivolt.svg` } alt="site-logo" />
								</NavLink>
							</div>
							{/* For mobile */ }
							<div className="for-mobile">
								<CustomizedAccordions
									className="open"
									headerContent={ headerContext && headerContext.headerContent }
								/>
								
							</div>
						</div>
						<div className="menu-box">
							<ul className='menu'>
								{
									headerContext && headerContext.headerContent && headerContext.headerContent.map((obj, index) => {
										return <li
											className={ `has-child ${menu === `submenu-${index + 1}` ? 'active' : ''}` }
											key={ index }
											onMouseEnter={ (e) => {

												handleMenuClick(`submenu-${index + 1}`);
											} }

										>
											<a href="#!">{ obj.title } <ArrowSvg className='icon' /></a>
											<ul className='submenu' id={ `submenu-${index + 1}` }>
												{
													obj.subNav && obj.subNav.map((subNav, subNavIndex) => {
														return <li key={ `${index}-${subNavIndex}` }><NavLink key={`${index}-${subNavIndex}`} to={ subNav.path } onClick={ () => handleMenuOut('') } dangerouslySetInnerHTML={{__html:subNav.name}}></NavLink></li>;
													})
												}
											</ul>
										</li>;
									})
								}
							</ul>
						
						</div>
						<div className='header-buttons-wrapper'>
							{
								headerFooterContent ? headerFooterContent.cta_buttons && headerFooterContent.cta_buttons.map((obj, index) => {
									return (
										<>
											<CtaButton
												onMouseEnter={() => handleMenuOut('')}
												className={`${obj.is_solid ? 'button-solid' : 'button-outline'}`}
												key={index} to={obj.cta_button.url} target={obj.cta_button.target}
												>
													{ obj.cta_button.title }
											</CtaButton>
											{/* a tag is for mobile only to replace CtaButton */}
											<a
												key={(index + 1) * 10}
												className={"header-button " + (obj.is_solid ? "sign-up" : "sign-in")}
												href={obj.cta_button.url} target={props.target ?? "_blank"}
											/>
										</>
									)}) 
									:
									<>
										<CtaButton className='button-outline'>Sign in</CtaButton>
										<CtaButton className='button-solid'>Open an account</CtaButton>
									</>
							}
							<LanguageDropdown/>
						</div>
					</div>
				</div>
			</div>
			{/* header end */ }

			<div className="headerclone" id="headerClone"></div>

			<div className={ `${menu && menu !== "" ? 'menu-open' : 'menu-close'} ` } id="menu-open" onMouseOut={ () => handleMenuOut('') } style={ { height: `${menuHeight}px`, transition: '.6s ease-in-out' } }></div>
		</header>
	);
};

export default Header;
