// @ts-nocheck
import ReactLoadable from 'react-loadable';

const Home = ReactLoadable({
	loader: () => import('./features/home/container/home'),
	modules: ['./features/home/container/home'],
	webpack: () => [require.resolveWeak('./features/home/container/home')],
	loading: () => {
		return null;
	},
});

const Career = ReactLoadable({
	loader: () => import('./features/career/container/career'),
	modules: ['./features/career/container/career'],
	webpack: () => [require.resolveWeak('./features/career/container/career')],
	loading: () => {
		return null;
	},
});

const Contact = ReactLoadable({
	loader: () => import('./features/contact/container/contact'),
	modules: ['./features/contact/container/contact'],
	webpack: () => [require.resolveWeak('./features/contact/container/contact')],
	loading: () => {
		return null;
	},
});

const Industries = ReactLoadable({
	loader: () => import('./features/industries/container/industries'),
	modules: ['./features/industries/container/industries'],
	webpack: () => [
		require.resolveWeak('./features/industries/container/industries'),
	],
	loading: () => {
		return null;
	},
});

const Legal = ReactLoadable({
	loader: () => import('./features/legal/container/legal'),
	modules: ['./features/legal/container/legal'],
	webpack: () => [require.resolveWeak('./features/legal/container/legal')],
	loading: () => {
		return null;
	},
});

const Faq = ReactLoadable({
	loader: () => import('./features/faq/container/faq'),
	modules: ['./features/faq/container/faq'],
	webpack: () => [require.resolveWeak('./features/faq/container/faq')],
	loading: () => {
		return null;
	},
});

const Documentation = ReactLoadable({
	loader: () => import('./features/documentation/container/documentation'),
	modules: ['./features/documentation/container/documentation'],
	webpack: () => [
		require.resolveWeak('./features/documentation/container/documentation'),
	],
	loading: () => {
		return null;
	},
});

const Products = ReactLoadable({
	loader: () => import('./features/products/container/products'),
	modules: ['./features/products/container/products'],
	webpack: () => [
		require.resolveWeak('./features/products/container/products'),
	],
	loading: () => {
		return null;
	},
});

const Pricing = ReactLoadable({
	loader: () => import('./features/pricing/container/pricing'),
	modules: ['./features/pricing/container/pricing'],
	webpack: () => [require.resolveWeak('./features/pricing/container/pricing')],
	loading: () => {
		return null;
	},
});

const Onboarding = ReactLoadable({
	loader: () => import('./features/onboarding/container/onboarding'),
	modules: ['./features/onboarding/container/onboarding'],
	webpack: () => [
		require.resolveWeak('./features/onboarding/container/onboarding'),
	],
	loading: () => {
		return null;
	},
});

const Api = ReactLoadable({
	loader: () => import('./features/api/container/api'),
	modules: ['./features/api/container/api'],
	webpack: () => [require.resolveWeak('./features/api/container/api')],
	loading: () => {
		return null;
	},
});

const ApiContent = ReactLoadable({
	loader: () => import('./features/api/container/api-content'),
	modules: ['./features/api/container/api-content'],
	webpack: () => [require.resolveWeak('./features/api/container/api-content')],
	loading: () => {
		return null;
	},
});

const ApiSearch = ReactLoadable({
	loader: () => import('./features/api/container/api-search'),
	modules: ['./features/api/container/api-search'],
	webpack: () => [require.resolveWeak('./features/api/container/api-search')],
	loading: () => {
		return null;
	},
});

const LegalPage = ReactLoadable({
	loader: () => import('./features/legal/container/legalPage'),
	modules: ['./features/legal/container/legalPage'],
	webpack: () => [require.resolveWeak('./features/legal/container/legalPage')],
	loading: () => {
		return null;
	},
});

export {
	Home,
	Career,
	Contact,
	Industries,
	Api,
	Legal,
	Faq,
	Documentation,
	Products,
	Pricing,
	Onboarding,
	ApiContent,
	ApiSearch,
	LegalPage,
};
