import './loader.scss';
const SiteLoader = () => {
    return <div className="logoLoading">
        <figure>
            <img src={`${process.env.PUBLIC_URL}/images/demivolt.svg`}/>
            <span className='loading-text'>LOADING</span>
        </figure>
    </div>;
};

export default SiteLoader;