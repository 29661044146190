import React from 'react';
const Button = (props) => {
    return (
        <a className={ `button ${props.className}` }
            onMouseEnter={ props.onMouseEnter }
            href={ props.to || '/' }
            target={ props.target !== "" ? "_blank" : "_self" }
        > { props.children }</a >

    );
};

export default Button;
