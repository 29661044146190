import React, { useState } from 'react';
import "./languageDropdownStyle.scss"

let DefaultValue = localStorage.getItem("languagePref") ||  "English";

const LanguageDropdown = () => {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const handleDropdownOnClick = () => {
		setIsDropdownVisible(!isDropdownVisible);
	}

    return (
		// To make it close on click outside: https://stackoverflow.com/a/37491578/9819103
		<div className='language-dropdown-wrapper' tabIndex={0} onFocus={handleDropdownOnClick} onBlur={handleDropdownOnClick}>
			{ isDropdownVisible &&
				<>
					<span className='language-arrow-up'></span>
					<div className='language-select'>
						<span className={DefaultValue === "English" ? "active" : ''} onClick={() => setValue('English')}>English</span>
						<span className={DefaultValue === "Chinese" ? "active" : ''} onClick={() => setValue('Chinese')}>中文</span>
						<span className={DefaultValue === "Lithuanian" ? "active" : ''} onClick={() => setValue('Lithuanian')}>Lietuvių</span>
					</div>
				</>
			}
		</div>

    );
};

const setValue = (language) => {
	localStorage.setItem("languagePref", language);
	window.location.reload();
}


export { setValue, LanguageDropdown };
