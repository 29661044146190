import queryString from 'query-string';
import axios from 'axios';

// eslint-disable-next-line
let ApiConfig_data = {} , countryconfig = {};

axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        countryconfig = {
            countryName: data.country_name,
            countryCode: data.country_calling_code
        };
});

if(localStorage.getItem("languagePref")==="Chinese"){

		ApiConfig_data = {
				baseUrl: `${process.env.REACT_APP_BASE_URL}`,
				basePagePath: 'wp-json/wp/v2/pages',
				path: {
					home: 'wp-json/wp/v2/pages/877',//2
					header: 'zh-hans/wp-json/menus/v1/menus/23', //2
					headerFooterAssets: 'zh-hans/wp-json/acf/v3/options/option',//options
					career: 'wp-json/wp/v2/pages/916',
					contact: 'wp-json/wp/v2/pages/879',
					industries: 'wp-json/wp/v2/pages/902',
					legal: 'wp-json/wp/v2/pages/869',
					faq: 'wp-json/wp/v2/pages/881',
					products: 'wp-json/wp/v2/pages/905',
					pricing: 'wp-json/wp/v2/pages/895',
					documentation: 'wp-json/wp/v2/pages/915',
					onboarding: 'wp-json/wp/v2/pages/921',//374
					api: 'wp-json/wp/v2/pages/868',
					apiContent: 'wp-json/wp/v2/website_api/',
			}
		} 
}
else if(localStorage.getItem("languagePref")==="Lithuanian"){

		ApiConfig_data = {
				baseUrl: `${process.env.REACT_APP_BASE_URL}`,
				basePagePath: 'wp-json/wp/v2/pages',
				path: {
					home: 'wp-json/wp/v2/pages/884',//2
					header: 'lt/wp-json/menus/v1/menus/24', //2
					headerFooterAssets: 'lt/wp-json/acf/v3/options/option',//options
					career: 'wp-json/wp/v2/pages/867',
					contact: 'wp-json/wp/v2/pages/890',
					industries: 'wp-json/wp/v2/pages/913',
					legal: 'wp-json/wp/v2/pages/873',
					faq: 'wp-json/wp/v2/pages/892',
					products: 'wp-json/wp/v2/pages/882',
					pricing: 'wp-json/wp/v2/pages/906',
					documentation: 'wp-json/wp/v2/pages/919',
					onboarding: 'wp-json/wp/v2/pages/923',//374
					api: 'wp-json/wp/v2/pages/874',
					apiContent: 'wp-json/wp/v2/website_api/',
				}
		} 
}
else{
	
		ApiConfig_data = {
				baseUrl: `${process.env.REACT_APP_BASE_URL}`,
				basePagePath: 'wp-json/wp/v2/pages',
				path: {
					home: 'wp-json/wp/v2/pages/718',//2
					header: 'wp-json/menus/v1/menus/22', //2
					headerFooterAssets: 'wp-json/acf/v3/options/option',//options
					career: 'wp-json/wp/v2/pages/139',
					contact: 'wp-json/wp/v2/pages/175',
					industries: 'wp-json/wp/v2/pages/225',
					legal: 'wp-json/wp/v2/pages/266',
					faq: 'wp-json/wp/v2/pages/280',
					products: 'wp-json/wp/v2/pages/308',
					pricing: 'wp-json/wp/v2/pages/336',
					documentation: 'wp-json/wp/v2/pages/367',
					onboarding: 'wp-json/wp/v2/pages/643',//374
					api: 'wp-json/wp/v2/pages/446',
					apiContent: 'wp-json/wp/v2/website_api/',
				}
		}
}

const API_CONFIG = {...ApiConfig_data};

const getUrl = (url, params) => {
	let urlString = `${API_CONFIG.baseUrl}/${url}`;
	if (params) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};

export { API_CONFIG, getUrl };
