import createDataContext from './createDataContext';

const headerFooterReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_HEADER_FOOTER_SUCCESS':
			return {
				...state,
				headerFooterContent: { ...action.payload },
				isLoading: action.isLoading,
			};

		default:
			return state;
	}
};

const fetchHeaderFooterData = (dispatch) => {
	return ({ headerFooterContent, isLoading }) => {
		dispatch({
			type: 'FETCH_HEADER_FOOTER_SUCCESS',
			payload: headerFooterContent,
			isLoading: isLoading,
		});
	};
};

export const { Context, Provider } = createDataContext(
	headerFooterReducer,
	{
		fetchHeaderFooterData,
	},
	{
		headerFooterContent: null,
		isLoading: true,
	}
);
