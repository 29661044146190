import createDataContext from './createDataContext';

const headerReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_HEADER_SUCCESS':
			return {
				...state,
				headerContent: [...action.payload],
			};

		default:
			return state;
	}
};
const fetchHeaderData = (dispatch) => {
	return ({ headerContent }) => {
		dispatch({ type: 'FETCH_HEADER_SUCCESS', payload: headerContent });
		//console.log(headerContent);	
	};
};
export const { Context, Provider } = createDataContext(
	headerReducer,
	{
		fetchHeaderData,
	},
);

