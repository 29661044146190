import React, { useContext, useEffect } from 'react';

import { Context as HeaderFooterAssetContext } from '../../shared/context/Assets-Context';
import { Context as HeaderContext } from '../../shared/context/Header-Context';
import Header from '../../shared/components/header/header';
import Footer from '../../shared/components/footer/footer';
import SiteLoader from '../../shared/components/loader/siteLoader';
import httpService from '../../shared/services/http.services';
import { API_CONFIG } from '../../shared/constants/api';


const Layout  = ({ children }) => {
    const headerFooterAssetContext = useContext(HeaderFooterAssetContext);
	const { fetchHeaderData } = useContext(HeaderContext);

    useEffect(() => {
        const fetchItems = async () => {
            const items = await getHeaderContent();
            const assetsItem = await getHeaderFooterAssets();
            fetchHeaderData({ headerContent: items });
            headerFooterAssetContext.fetchHeaderFooterData({ headerFooterContent: assetsItem, isLoading: false });
        }
        fetchItems();
    }, []);

    /**
	 * getHeaderContent method is used to fetch menu for header
	 */
	const getHeaderContent = async () => {
		try {
			const headerData = await httpService.get(API_CONFIG.path.header);
			if (headerData) {
				let items = headerData.items.map((obj) => {
					let navObject = {};
					navObject['title'] = obj.post_title;
					navObject['subNav'] = obj.child_items.map((childObject) => {
						return {
							name: childObject.post_title,
							path: childObject.url
						};
					});
					return navObject;
				});
				return items;
			}
		} catch (error) {
			console.log(error);
		}
	};

    /**
	 * getHeaderFooterAssets method is used to call api and get footer content
	 */
	const getHeaderFooterAssets = async () => {
		try {
			const assets = await httpService.get(API_CONFIG.path.headerFooterAssets);
			if (assets && assets.acf) {
				const favicon = document.getElementById("favicon-icon");
				if (favicon && assets.acf.favicon) {
					favicon.href = assets.acf && assets.acf.favicon.url;
				}
				return assets.acf;
			}
		} catch (error) {
			console.log(error);
		}
	};

    return (
        <div>
            { headerFooterAssetContext.state.isLoading &&
                <div className="loadingWrap" >
                    <SiteLoader />
                </div>
            }
            { !headerFooterAssetContext.state.isLoading &&
                <>
                    <Header />
                    <div className="main-wrapper">
                        { children }
                    </div>
                    <Footer />
                </>
            }
        </div>
    );
}

export default Layout;