// @ts-nocheck
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import {
	Home,
	Career,
	Contact,
	Industries,
	Api,
	Legal,
	Faq,
	Documentation,
	Products,
	Pricing,
	Onboarding,
	ApiContent,
	ApiSearch,
	LegalPage,
} from './routes';

import { Provider as HeaderProvider } from './shared/context/Header-Context';
import { Provider as HeaderFooterAssetsProvider } from './shared/context/Assets-Context';
import Layout from './hoc/layout/layout';
class App extends React.PureComponent {
	render() {
		return (
			<div className='App'>
				<HeaderProvider>
					<HeaderFooterAssetsProvider>
						<Layout>
							<Switch>
								<Route path='/' component={Home} exact />
								<Route path='/career' component={Career} exact />
								<Route path='/contact' component={Contact} exact />
								<Route path='/industries' component={Industries} exact />
								<Route path='/api' component={Api} exact />
								<Route path='/api/search' component={ApiSearch} exact />
								<Route path='/api/:id' component={ApiContent} exact />
								<Route path='/api/:id/:subId' component={ApiContent} exact />
								<Route path='/legal' component={Legal} exact />
								<Route path='/legal/:id' component={LegalPage} exact />
								<Route path='/faq' component={Faq} exact />
								<Route path='/documentation' component={Documentation} exact />
								<Route path='/products' component={Products} exact />
								<Route path='/products/:id' component={Products} exact />
								<Route path='/Pricing' component={Pricing} exact />
								<Route path='/Onboarding' component={Onboarding} exact />

								<Redirect from='' to='/' />
							</Switch>
						</Layout>
					</HeaderFooterAssetsProvider>
				</HeaderProvider>
			</div>
		);
	}
}

export default App;
