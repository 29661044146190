import React, { useContext } from 'react';

import CtaButton from '../ctaButton/cta';
import './footerStyle.scss';
import { ReactComponent as LinkdinIcon } from '../../../assets/images/linkedin_white.svg';

import { Context as HeaderFooterContext } from '../../context/Assets-Context';
import { NavLink } from 'react-router-dom';

const Footer = (props) => {
    const { state } = useContext(HeaderFooterContext);
    const footerAssets = state.headerFooterContent;
    const footerMenu = ['API for developers', 'Legal', 'Privacy Policy', 'Cookie Policy'];
    return (<div className='site-footer' id="site-footer">
        {
            footerAssets && footerAssets.is_display_blue_strip_section &&
            <div className="accountaccess-box" id="footer">
                <div className="container">
                    <div className="row-wrap">
                        <div className="box-12 box-md-6 content-box">
                            <div className="inner-box">
                                <p>{ footerAssets && footerAssets.strip_content }</p>
                            </div>
                        </div>
                        <div className="box-12 box-md-5 button-box">
                            <div className="inner-box">
                                {
                                    footerAssets && footerAssets.strip_cta_buttons ? footerAssets.strip_cta_buttons.map((obj, index) => {
                                        return <CtaButton
                                            key={ obj.cta_button.title }
                                            className={ `${obj.is_solid ? 'button-solid' : 'button-outline'}` }
                                            to={ obj.cta_button.url } target={ obj.cta_button.target }
                                        >{ obj.cta_button.title }</CtaButton>;
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        }
        <div className="bottom-info">
            <div className="container">
                <div className="row-wrap">
                    <div className="box-12 box-md-6 info-box">
                        <div className="inner-box">
                            {
                                footerAssets ? footerAssets.footer_left_info && <div dangerouslySetInnerHTML={ { __html: footerAssets.footer_left_info } } /> : <p>DEMIVOLT UAB is a company registered in Lithuania (a European Union member state) with company code 305562462; its registered address is Jogailos st. 4, Vilnius, Lithuania. DEMIVOLT UAB is an Electronic Money Institution (EMI license No. XX) authorised and regulated by the Bank of Lithuania (BoL). BoL is subject to the regulations of the European Central Bank. Our licences can be found <a href="#!">here</a>.</p>

                            }
                            <div className="app-button">
                                {
                                    footerAssets && footerAssets.app_store_icon && <a href={ footerAssets.app_store_link } target="_blank" rel="noreferrer"><img src={ footerAssets ? footerAssets.app_store_icon && footerAssets.app_store_icon.url : `${process.env.PUBLIC_URL}/images/apple_store_label.svg` } alt="app-store" /></a>
                                }
                                {
                                    footerAssets && footerAssets.google_play_icon &&
                                    <a href={ footerAssets.google_play_link } target="_blank" rel="noreferrer"><img src={ footerAssets ? footerAssets && footerAssets.google_play_icon && footerAssets.google_play_icon.url : `${process.env.PUBLIC_URL}/images/google_play_label.svg` } alt="google-play" /></a>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="box-6 box-md links-box">
                        <div className="inner-box">
                            <ul>
                                {
                                    footerAssets ? footerAssets.footer_links && footerAssets.footer_links.map((obj, index) => {

                                        return (
                                            <li key={ index }>
                                                {
                                                    obj.is_outside ?
                                                        <a href={ obj.link.url } target={ obj.link.target !== "" ? "_blank" : "_self" }>{ obj.link.title }</a> :
                                                        <NavLink to={ obj.link.url } target={ obj.link.target !== "" ? "_blank" : "_self" }>{ obj.link.title }</NavLink>
                                                }
                                            </li>
                                        );
                                    }) : <>
                                            {
                                                footerMenu.map((obj, index) => {
                                                    return <li key={ index }> <a href={ "#!" } target={ obj.link.target === "" ? "_self" : "_blank" } rel="noreferrer">{ obj }</a> </li>;
                                                })
                                            }
                                        </>
                                }

                            </ul>

                        </div>
                    </div>
                    <div className="box-6 box-md-3 contact-box">
                        {
                            footerAssets ? footerAssets.footer_right_info && <div className="
                        inner-box">
                                {
                                    footerAssets && footerAssets.contact_us_link && <NavLink to={ footerAssets.contact_us_link.url } className="contact-us-link" target={ footerAssets.contact_us_link.target === "" ? "_self" : "_blank" } >{ footerAssets.contact_us_link.title }</NavLink>
                                }
                                <div dangerouslySetInnerHTML={ { __html: footerAssets.footer_right_info } } />
                                <div className="sociallist">
                                    {
                                        footerAssets && footerAssets.social_media_section && footerAssets.social_media_section.map((obj, index) => {
                                            return <a href={ obj.link } key={ index } target="_blank" rel="noreferrer">
                                                <img className={ `icon hover linkding-icon ` } alt="hoveer" src={ obj.hover_icon && obj.hover_icon.url } />
                                                <img className="icon linkding-icon" alt="linked-in" src={ obj.icon && obj.icon.url } />
                                            </a>;
                                        })
                                    }
                                </div>

                            </div> :
                                <div className="inner-box">
                                    <h4>Contact us</h4>
                                    <dl>
                                        <dt>General inquires:</dt>
                                        <dd><a href="mailto:info@demivolt.com">info@demivolt.com</a></dd>
                                        <dt>Customer support:</dt>
                                        <dd><a href="mailto:support@demivolt.com">support@demivolt.com</a></dd>
                                    </dl>
                                    <div className="sociallist">
                                        <a href="#!">
                                            <LinkdinIcon className='icon linkding-icon' />
                                        </a>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div >);
};

export default Footer;
